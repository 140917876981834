import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`About`}</h2>
    <blockquote>
      <p parentName="blockquote">{`“It’s not a bug. It’s an undocumented feature!”`}</p>
    </blockquote>
    <p>{`I take the web seriously. This frontier has changed human-kind forever, and I am comitted to helping shape it for the better. Learn new technology, don't forget where we came from, do no evil. Let's create something together `}<a parentName="p" {...{
        "href": "mailto:luca.junghans@pm.me"
      }}>{`luca.junghans@pm.me`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      