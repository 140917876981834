import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Get in touch`}</h2>
    <p>{`Say `}<a parentName="p" {...{
        "href": "luca.junghans@pm.me"
      }}>{`Hi`}</a>{` or find me on: `}<a parentName="p" {...{
        "href": "https://github.com/LJunghansCode"
      }}>{`GitHub`}</a>{` & `}<a parentName="p" {...{
        "href": "https://www.instagram.com/luca_junghans"
      }}>{`Instagram`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      